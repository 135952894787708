/**
 *  Created by panwei on 2018/7/11 下午4:06.
 */
import { app as api } from '@ekuaibao/whispered'
import {
  getQualification,
  setConfirmInvoice,
  getInvoiceConfirmCheck,
  getInvoiceRelation,
  getBatchInfoByBatchId,
  getUnifyCorporationList,
  remindSupInvoice,
  getInvoiceRules,
  getLog,
  getInvoiceCity,
  getRuleListForReason
} from './bill-invoice-manage-action'

export default [
  {
    id: '@invoice-manage',
    reducer: () => import('./bill-invoice-manage-reducer'),
    'get:qualification'(param) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(getQualification(param))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'confirm:added:invoice': (params) => {
      return api.dispatch(setConfirmInvoice(params))
    },
    'get:operate:log': (params) => {
      return api.dispatch(getLog(params))
    },
    'wait:invoice:confirmCheck': (params) => {
      return api.dispatch(getInvoiceConfirmCheck(params))
    },
    'dispatch:getInvoiceRelation': (params) => api.dispatch(getInvoiceRelation(params)),
    'dispatch:getBatchInfoByBatchId': (params) => api.dispatch(getBatchInfoByBatchId(params)),
    'import:getUnifyCorporationList': () => getUnifyCorporationList,
    'remind:supply:invoice': (params) => api.dispatch(remindSupInvoice(params)),
    'get:invoice:mapping:rule': () => getInvoiceRules(),
    'get:invoice:city': (param) => getInvoiceCity(param),
    'get:invoice:rule:list': () => {
      return api.dispatch(getRuleListForReason())
    }
  },

  {
    path: '/invoice-manage',
    ref: '/',
    onload: () => import('./InvoiceManage/InvoiceManangeView')
  },
  {
    path: '/invoice-disabled',
    ref: '/',
    onload: () => import('./DisabledInvoice/DisabledInvoiceView')
  },
  {
    path: '/adv-invoice-manage',
    ref: '/',
    onload: () => import('./InvoiceManage/InvoiceManangeView')
  },
  {
    path: '/waitting-invoice-manage',
    ref: '/',
    onload: () => {
      if (window.isNewHome) {
        return import('./WaittingInvoiceMange/WaittingInvoiceMange')
      } else {
        return import('./WaittingInvoiceMange/old/OldWaittingInvoiceMange')
      }
    }
  },
  {
    path: '/unite-invoice-manage',
    ref: '/',
    onload: () => import('./InvoiceUnify')
  },
  {
    path: '/supplier-unite-invoice-manage',
    ref: '/',
    onload: () => import('./InvoiceUnify')
  },
  {
    path: '/invoice-setting',
    ref: '/',
    onload: () => import('./InvoiceMappingRule/InvoiceSetting')
  },
  {
    path: '/invoice-rule',
    ref: '/',
    onload: () => import('./InvoiceRule/InvoiceRuleConfigList')
  },
  {
    path: '/importInvoice-pool',
    ref: '/',
    onload: () => import('./InvoiceTaxDeduction/ImportInvoicePool')
  },
  {
    path: '/importInvoice-taxCheck',
    ref: '/',
    onload: () => import('./InvoiceTaxDeduction/InvoiceTaxCheck')
  },
  {
    path: '/importInvoice-taxCheck-count',
    ref: '/',
    onload: () => import('./InvoiceTaxDeduction/InvoiceTaxCheckCount')
  },
  {
    path: '/importInvoice-data-preview',
    ref: '/',
    onload: () => import('./InvoiceTaxDeduction/dataPreview')
  },
  {
    path: '/importInvoice-setting',
    ref: '/',
    onload: () => import('./InvoiceTaxDeduction/setting')
  },
  {
    path: '/invoice-review',
    ref: '/',
    onload: () => import('./review/ReviewManagerView')
  },
  {
    path: '/mine-wait-invoice-manage',
    ref: '/',
    onload: () => import('./mine-wait-invoice-manage')
  },
  {
    path: '/invoice-certification',
    ref: '/',
    onload: () => import('./checkConfirm/CheckConfirmManage')
  },
  {
    path: '/invoice-priceTaxSeparated',
    ref: '/',
    onload: () => import('./InvoiceTaxRule/InvoiceTaxRuleView')
  },
  {
    point: '@@layers',
    prefix: '@invoice-manage',
    onload: () => import('./layers')
  }
]
