/**
 *  Created by panwei on 2018/7/26 上午10:45.
 */
import parseQuery2Select, {
  parseQuerySelectNoLimit,
  parseQuery2Select as parseQuery2SelectCopy,
  parseQuery2SelectNoPage
} from '@ekuaibao/lib/lib/parseQuery2Select'

import key from './key'
import { Resource } from '@ekuaibao/fetch'
const invoiceAction = new Resource('/api/v2/invoice')
const invoiceDisable = new Resource('/api/v2/invoiceDisable')
const invoiceMark = new Resource('/api/v2/invoiceMark')

import { QuerySelect } from 'ekbc-query-builder'
import parseSelectUsePropertySet from '@ekuaibao/lib/lib/parseSelectUsePropertySet'
import { app as api } from '@ekuaibao/whispered'
import { cloneDeep } from 'lodash'
import { Fetch } from '@ekuaibao/fetch'
const expenseManage = new Resource('/api/flow/v2/filter')
const backlogs = new Resource('/api/flow/v2/backlogs')
const reviewInvoice = new Resource('/api/review')
const importInvoice = new Resource('/api/fastimport')
// const expresses = new Resource('/api/expresses/v2/flowids')
const getFlowIds = new Resource('/api/v2/invoice/review/getFlowIds')
const certification = new Resource('/api/v2/certification')
const dataLink = new Resource('/api/v2/datalink/entity')
const newInvoice = new Resource('/api/v2/new/invoice/unify')
const invoiceActionV3 = new Resource('/api/v3/invoice')
const invoiceActionV2Excel = new Resource('/api/v2/easyexcel/invoiceFlow')
const advInvoiceAction = new Resource('/api/v2/invoiceFlow')
const supplierAction = new Resource('/api/supplier/v3')
const organization = new Resource('/api/v1/organization')
const invoiceDataChange = new Resource('/api/v1/invoice/invoiceDataChange')
import { showMessage } from '@ekuaibao/show-util'

const AdvInvoiceManageResource = new Resource('/api/flow/v2/filter')
const InvoiceManageResource = new Resource('/api/flow/v2/filter')
const WaitInvoiceManage = new Resource('/api/flow/v2/filter')

// 保存所有发票beta更改
export async function getTableScenes(param) {
  const id = ''
  const data = await AdvInvoiceManageResource.GET('/$type', { type: id ? param + id : param })
  if (!data.value) return null
  const { filter } = data.value
  const jsonParseFilter = JSON.parse(filter)
  if (jsonParseFilter.defaultColumns) {
    return jsonParseFilter.defaultColumns
  }
}

// 保存所有发票更改
export async function getAllTableScenes(param) {
  const id = ''
  const data = await InvoiceManageResource.GET('/$type', { type: id ? param + id : param })
  if (!data.value) return null
  const { filter } = data.value
  const jsonParseFilter = JSON.parse(filter)
  if (jsonParseFilter.defaultColumns) {
    return jsonParseFilter.defaultColumns
  }
}

// 保存待开发票更改
export async function getWaitTableScenes(param) {
  const id = ''
  const data = await WaitInvoiceManage.GET('/$type', { type: id ? param + id : param })
  if (!data.value) return null
  const { filter } = data.value
  const jsonParseFilter = JSON.parse(filter)
  if (jsonParseFilter.defaultColumns) {
    return jsonParseFilter.defaultColumns
  }
}

// 统一开票更改
export async function getUnifiedInvoiceScenes(param) {
  const id = ''
  const data = await WaitInvoiceManage.GET('/$type', { type: id ? param + id : param })
  if (!data.value) return null
  const { filter } = data.value
  return filter
}

/**
 *  保存按钮显示隐藏
 * @param type 保存更改类型：自定义常量
 * @param filter 过滤后的表单列：[form.xxx,form.xxx]
 */

export function sendReqToSaveColumsTable(type, filter) {
  let params = { type: type, filter }
  return AdvInvoiceManageResource.POST('/$type', params)
}

export function sendAllReqToSaveColumsTable(type, filter) {
  let params = { type: type, filter }
  return InvoiceManageResource.POST('/$type', params)
}

export function sendWaitReqToSaveColumsTable(type, filter) {
  let params = { type: type, filter }
  return WaitInvoiceManage.POST('/$type', params)
}

export function sendUnifiedInvoiceScenes(type, filter) {
  let params = { type: type, filter }
  return WaitInvoiceManage.POST('/$type', params)
}

export function getUnifyCorporationList() {
  return {
    type: key.CORPORATION_LIST,
    payload: invoiceAction.GET('/unify/corporation/list')
  }
}

//添加统一开票方
export function addUnifyCorporation(param) {
  return {
    type: key.ADD_CORPORATION,
    payload: invoiceAction.POST('/unify/corporation', param)
  }
}

//获取未开票列表
export function getUnifyUndo(param) {
  return {
    type: key.INVOICE_UNDO_LIST,
    payload: invoiceAction.POST('/unify/undo', param)
  }
}

//获取开票中已开票列表
export function getUnfityInvoiceList(param, queryParam) {
  const query = new QuerySelect().select(`legalEntityId(...),feeTypeId(...),...`).value()
  return {
    type: key.UNIFY_INVOICE_LIST,
    payload: invoiceAction.POST('/unify/list', { ...param, ...query }, queryParam)
  }
}

export function confirmInvoiceBath(param) {
  return {
    type: key.CONFIRM_INVOICE_BATCH,
    payload: invoiceAction.PUT('/unify/batch/confirm/$batchId', param)
  }
}

//线下开票
export function offineInvoice(param, qs) {
  return {
    type: key.OFFINE_INVOICE,
    payload: invoiceAction.POST('/unify/offline', param, qs)
  }
}

//线上开票
export function onlineInvoice(param, qs) {
  return {
    type: key.ONLINE_INVOICE,
    payload: invoiceAction.POST('/unify/online', param, qs)
  }
}

//批次上添加发票
export function addInvoiceToBatch(param) {
  return {
    type: key.ADD_INVOICE_TO_BATCH,
    payload: invoiceAction.PUT('/unify/batch/finish', param)
  }
}

//删除批次
export function deleteBatch(param) {
  return {
    type: key.DELETE_BATCH,
    payload: invoiceAction.DELETE('/unify/batch/$batchid', param)
  }
}

//统一开票选择全部
export function untifySelectAll(param, query) {
  return {
    type: key.UNTIFY_SELECT_ALL_BATCH,
    payload: invoiceAction.POST('/unify/undo/selectall', param, query)
  }
}

export function getInvoiceList(params) {
  return {
    type: key.GET_INVOICE_LIST,
    payload: invoiceAction.POST('/search', params)
  }
}

export function getInvoiceItemList(params) {
  const query = new QuerySelect().select(`masterId(...),...`).value()

  return {
    type: key.GET_INVOICE_ITEM_LIST,
    payload: invoiceAction.POST('/item/search', { ...query, ...params })
  }
}

export function getInvoiceRelation(id) {
  return {
    type: key.GET_INVOICE_RELATION,
    payload: invoiceAction.GET('/item/$id/relation', { id })
  }
}

export function getInvoiceByEntityId(params) {
  // let query = new QuerySelect().select(`masterId(...),...`).value()
  return {
    type: key.GET_INVOICE_BY_ENTITYID,
    payload: invoiceAction.POST('/inuse/search', { ...params })
  }
}
//查询已禁用列表
export function getDisabledInvoiceByEntityId(params) {
  return {
    type: key.GET_DISABLED_INVOICE_BY_ENTITYID,
    payload: invoiceDisable.POST('/search', { ...params })
  }
}
//取消禁用发票
export function cancelDisableInvoice(params) {
  return {
    type: key.POST_CANCEL_DISABLED_INVOICE,
    payload: invoiceDisable.POST('/cancelDisable', params)
  }
}


//取消标记发票
export function cancelMarkInvoice(params) {
  return {
    type: key.POST_CANCEL_DISABLED_INVOICE,
    payload: invoiceMark.POST('/cancelDisable', params)
  }
}

//获取批次下面的费用列表
export function getBatchDetails(param) {
  return {
    type: key.GET_INVOICE_BATCH_DETAILS,
    payload: invoiceAction.POST('/unify/batch/feeDetail/$batchId', param)
  }
}

//根据批次Id获取批次详情
export function getBatchInfoByBatchId(param) {
  const query = new QuerySelect().select(`unifyInvoiceCorporationId(...),...`).value()
  return {
    type: key.GET_BATCH_INFO_BY_ID,
    payload: invoiceAction.POST('/unify/batch/info/$batchId', { ...param, ...query })
  }
}

function getPropertySet() {
  return api
    .dataLoader('@common.globalFields')
    .load()
    .then((resp) => resp.data)
}

function handleScene(scene, dimensionItems, prefix) {
  let sceneFiltersQuery = '',
    qs = undefined
  if (!scene) {
    return { sceneFiltersQuery, qs }
  }
  const filtersFixer = api.require('@lib/filtersFixer')
  sceneFiltersQuery = filtersFixer(scene, prefix, dimensionItems)
  return { sceneFiltersQuery, qs }
}

export function searchWaitingInvoiceList(param, queryParam) {
  const { params, scene, dimensionItems } = param
  return getPropertySet()
    .then((propertySet) => {
      const __params = cloneDeep(params)
      let invoiceRemindStr = ''
      if (params.filters && params.filters.invoiceRemind) {
        const { invoiceRemind = [] } = params.filters.invoiceRemind
        if (invoiceRemind.length) {
          const [value] = invoiceRemind
          if (invoiceRemind.length === 1 && !value) {
            delete __params.filters.invoiceRemind
            invoiceRemindStr = `invoiceRemind==${params.filters.invoiceRemind.invoiceRemind} || isNull(invoiceRemind)`
          } else if (invoiceRemind.length === 2) {
            delete __params.filters.invoiceRemind
          }
        }
      }
      let query = parseQuery2Select(__params, undefined, 'flow')
      if (invoiceRemindStr) {
        query = query.filterBy(invoiceRemindStr)
      }
      const content = parseSelectUsePropertySet(propertySet)
      const { sceneFiltersQuery } = handleScene(scene, dimensionItems)
      return query
        .select(`ownerId(id,name),form(${content},...),...`)
        .filterBy(`state.in("paying","approving","paid","archived","sending","receiving")`)
        .filterBy(sceneFiltersQuery)
        .value()
    })
    .then((data) => {
      return invoiceAction.POST('/wait/search', { ...data }, queryParam)
    })
}

export function getInvoiceListTotal(params) {
  return {
    type: key.GET_INVOICE_LIST_TOTAL,
    payload: invoiceAction.POST('/search/calc', params)
  }
}

export function getAdvInvoiceListTotal(params) {
  return {
    type: key.GET_ADV_INVOICE_LIST_TOTAL,
    payload: advInvoiceAction.POST('/search/calc', params)
  }
}

export function getInvoiceItemTotal(params) {
  return {
    type: key.GET_INVOICE_ITEM_TOTAL,
    payload: invoiceAction.POST('/item/search/calc', params)
  }
}

//计算批次下面的费用明细金额
export function calcBatchFeeDetailMoney(param) {
  return {
    type: key.CALC_BACTH_FEEDETAIL_MONEY,
    payload: invoiceAction.POST('/unify/batch/feeDetail/calc/$batchId', param)
  }
}

//获取企业资质
export function getQualification(param) {
  return {
    type: key.GET_INVOICE_QUALIFICATION,
    payload: invoiceAction.GET('/unify/qualification', param)
  }
}

//重新发起
export function getRetryBatch(param, query) {
  return {
    type: key.GET_RETRY_BATCH,
    payload: invoiceAction.POST('/unify/online/retry/$batchid', param, query)
  }
}

//刷新开票中列表
export function refreshInvoicingList(param) {
  return {
    type: key.REFRESH_INVOICING_LIST,
    payload: invoiceAction.GET('/unify/online/state/refresh', param)
  }
}

//确认补充发票
export function setConfirmInvoice(params) {
  return {
    type: key.SET_CONFIRM_INVOICE,
    payload: params.detailId
      ? invoiceAction.PUT('/wait/confirm/$flowId/$detailId', params)
      : invoiceAction.PUT('/wait/confirm', params)
  }
}

export function getInvoiceConfirmCheck(param) {
  return {
    type: key.WAIT_INVOICE_CONFIRMCHECK,
    payload: invoiceAction.PUT('/wait/confirmCheck', param)
  }
}

// 提醒补充发票
export function remindSupInvoice(params) {
  return {
    type: key.REMIND_SUP_INVOICE,
    payload: invoiceAction.POST(`/wait/remind/[flowIds]`, params)
  }
}

// 选择全部提醒补充发票
export function remindSupInvoiceAll(param, queryParam) {
  const { params, scene, dimensionItems } = param
  const query = parseQuerySelectNoLimit(params, undefined, 'flow')
  const { sceneFiltersQuery } = handleScene(scene, dimensionItems)
  const queryString = query
    .select(`...`)
    .filterBy(`state.in("paying","approving","paid","archived","sending","receiving")`)
    .filterBy(sceneFiltersQuery)
    .value()
  return {
    type: key.REMIND_SUP_INVOICE_ALL,
    payload: invoiceAction.POST('/wait/remindAll', { ...queryString }, queryParam)
  }
}
export async function getRuleList(param) {
  try {
    const result = await invoiceAction.GET('/usageNorm/v2', param)
    return result.items
  } catch (e) {}
}

// 通过发票规范列表获取发票连号配置
export function getRuleListForReason() {
  return {
    type: key.GET_RULE_LIST_FOR_REASON,
    payload: invoiceAction.GET('/usageNorm')
  }
}

export async function getInvoiceFields() {
  try {
    const res = await dataLink.GET('/getEntityFields')
    return res.value
  } catch (e) {
    console.error(e)
  }
}

export async function setRuleStatus(params) {
  return invoiceAction.PUT('/usageNorm/$id/$active', params)
}

export async function updateRule(rule) {
  const keys = [
    'normType',
    'invoiceType',
    'range',
    'control',
    'active',
    'id',
    'field',
    'extConditions',
    'name',
    'description',
    'dateCaliber',
    'belong',
    'requisitionSpecificationId',
    'exemption',
    'conditions',
    'riskDescription',
    'codeBelong',
    'checked',
    'serialCheckRange',
    'tagType',
    'disableControl',
    'triggerTimes'
  ]
  const params = {}
  Object.keys(rule).forEach((item) => {
    if (keys.includes(item)) {
      params[item] = rule[item]
    }
  })
  return invoiceAction.POST('/usageNorm', params)
}
export function getInvoicesByIds(ids) {
  return {
    type: key.GET_INVOICE_BY_IDS,
    payload: invoiceAction.GET('/validation/[ids]', { ids })
  }
}

export function getInvoiceListById(params) {
  return invoiceAction.POST('/inuse/search', params)
}

export function getInvoiceRules() {
  return invoiceAction.GET('/mapping')
}

export function saveInvoiceRule(param) {
  return invoiceAction.POST('/mapping/$entityId', param)
}

export function getInvoiceCity(param) {
  return invoiceAction.GET('/mapping/getCity/[cities]', param)
}

export function getDiDiInvoiceManageScenes(type) {
  return expenseManage.GET('/$type', { type })
}

export function postDiDiInvoiceManageScenes(params) {
  return expenseManage.POST('/$type', { ...params })
}

//通过单据号找导入待审批单据
export function getBillInfoByCode(param) {
  return getPropertySet()
    .then((propertySet) => {
      const { code } = param
      const query = new QuerySelect()
      const content = parseSelectUsePropertySet(propertySet)
      query
        .select(
          `
        flowId(
          ownerId(id,name),
          form(${content},...),...),...`
        )
        .filterBy(`(type != "permit")`)
        .filterBy(`(flowId.form.code == "${code}")`)
      // .filterBy(`(state.in("APPROVING"))`)
      return { queryString: query.value() }
    })
    .then((data) => {
      return backlogs.POST('/search', data.queryString)
    })
}

export function uploadAttachmentInfo(data) {
  return reviewInvoice.POST('', { ...data }, null, null, { hiddenLoading: true })
}

export function importInvoiceInfo(data) {
  return reviewInvoice.POST('', { ...data }, null, null, { hiddenLoading: true })
}

export function getReviewInvoiceByFlowIds(params) {
  return {
    type: key.GET_REVIEW_INVOICE_BY_FLOWIDS,
    payload: reviewInvoice.POST('/byFlowIds', params)
  }
}

//手动确认复核/取消复核 单据
export function fnManualReviewFlow(params) {
  return {
    type: key.FN_MANUA_REVIEW_FLOW,
    payload: reviewInvoice.POST('/manualReviewFlow', params)
  }
}

//手动确认复核/取消复核 发票
export function fnManualReviewInvoice(params) {
  return {
    type: key.FN_MANUA_REVIEW_INVOICE,
    payload: reviewInvoice.POST('/manualReceiveInvoice', params)
  }
}

export function getReviewConfig() {
  return {
    type: key.GET_REVIEW_CONFIG,
    payload: reviewInvoice.GET('/config')
  }
}

export function setReviewConfig(params) {
  return {
    type: key.SET_REVIEW_CONFIG,
    payload: reviewInvoice.POST('/config', params)
  }
}

export async function getImportConfig() {
  return await importInvoice.GET('/config')
}

export async function setImportConfig(params) {
  return await importInvoice.POST('/config', params)
}

export function sendInvoiceReviewMessage(ids) {
  return {
    type: key.SEND_INVOICE_REVIEW_MESSAGE,
    payload: reviewInvoice.POST('/sendInvoiceReplenishRemind', ids)
  }
}

//查询当前人员操作的处于复核节点的单据列表
export function getWaitReviewFlow() {
  return {
    type: key.GET_WAIT_REVIEW_FLOW,
    payload: reviewInvoice.GET('/getWaitReviewFlow')
  }
}

export function getFlowIdsByTrackNum(num) {
  return {
    type: key.GET_FLOWIDS_BY_TRACKNUN,
    payload: getFlowIds.GET('/$num', num)
  }
}

export function getToBeConfirmedInvoiceList(params, columns) {
  return getPropertySet()
    .then((propertySet) => {
      const query = parseQuery2SelectCopy(params, undefined, columns)
      query.select(`submitterId(...),...`).filterBy(`active==true`)
      return { queryString: query.value() }
    })
    .then((data) => {
      return certification.POST('/search', data.queryString)
    })
}

export function tickInvoiceByFilePath(params) {
  return {
    type: key.TICK_INVOICE_BY_FILE_PATH,
    payload: certification.POST('/tick/byFilePath', params)
  }
}

export function tickInvoiceByFileKey(params) {
  return {
    type: key.TICK_INVOICE_BY_FILE_KEY,
    payload: certification.POST('/tick/byFileKey', params, null, null, { hiddenLoading: true })
  }
}

export function tickInvoiceByInvoiceId(params) {
  return {
    type: key.TICK_INVOICE_BY_InvoiceId,
    payload: certification.POST('/tick/byInvoiceId', params)
  }
}

export function createBatchByIds(params) {
  return {
    type: key.CREATE_BATCH_BY_IDS,
    payload: certification.PUT('/createBatch', params)
  }
}

export function getBatchList(params) {
  let query = parseQuery2SelectCopy(params)
  query.select(`staffId(...),...`)
  query = query.value()
  return {
    type: key.GET_BATCH_LIST,
    payload: certification.POST('/batch', { ...query })
  }
}

export function getTickBatchDetails(params) {
  const { batchId, queryParams } = params
  let query = parseQuery2SelectCopy(queryParams)
  query = query.value()
  return {
    type: key.GET_TICK_BATCH_DETAILS,
    payload: certification.POST('/getBatchDetail/$batchId', { batchId, ...query })
  }
}

export function removeInvoiceByIds(params) {
  return {
    type: key.REMOVE_INVOICE_BY_IDS,
    payload: certification.GET('/remove/[ids]', params)
  }
}

export function checkDeviceStatus(params) {
  return {
    type: key.CHECK_DEVICE_STATUS,
    payload: certification.GET('/checkDeviceStatus/[ids]', params)
  }
}

export function selectAllInvoice(params, columns) {
  let query = parseQuery2SelectNoPage(params, undefined, columns)
  query.select(`submitterId(...),...`).filterBy(`active==true`)
  query = query.value()
  return {
    type: key.SELECT_ALL_INVOICE,
    payload: certification.POST('/search/allMoney', { ...query })
  }
}

export function getIsCanCheck() {
  return {
    type: key.CERTIFICATION_CAN_CHECK,
    payload: certification.GET('/isCanCheck')
  }
}

export function getCertificationUrl() {
  return {
    type: key.GET_CERTIFICATION_URL,
    payload: certification.GET('/getCertificationUrl')
  }
}

export function getInvoiceTaxNums() {
  return {
    type: key.GET_INVOICE_TAX_NUMS,
    payload: invoiceAction.GET('/priceAndTaxSeparatedConfig/taxNums')
  }
}

export function getPriceAndTaxSeparatedList() {
  return {
    type: key.GET_PRICE_AND_TAX_SEPARATED_LIST,
    payload: invoiceAction.GET('/priceAndTaxSeparatedConfig')
  }
}

export function deleteInvoiceTaxSeparated(id) {
  return {
    type: key.DELETE_PRICE_AND_TAX_SEPARATED_LIST,
    payload: invoiceAction.DELETE('/priceAndTaxSeparatedConfig/$id', { id })
  }
}

export function saveInvoiceTaxSeparated(param) {
  return {
    type: key.SAVE_PRICE_AND_TAX_SEPARATED_LIST,
    payload: invoiceAction.PUT('/priceAndTaxSeparatedConfig', param)
  }
}

export function modifyInvoiceTaxSeparated(param) {
  return {
    type: key.MODIFY_PRICE_AND_TAX_SEPARATED_LIST,
    payload: invoiceAction.POST('/priceAndTaxSeparatedConfig', param)
  }
}

//新统一开票
export function getHoseTableColumns(params) {
  return {
    type: key.GET_HOSE_TABLE_COLUMNS,
    payload: newInvoice.POST('/undo', params)
  }
}

export function getHoseTableInvoicingColumns(params) {
  return {
    type: key.GET_HOSE_TABLE_INVOICING_COLUMNS,
    payload: newInvoice.POST('/list', params)
  }
}

export function BatchBilling(params) {
  return {
    type: key.BATCH_BILLING,
    payload: newInvoice.POST('/batch', params)
  }
}

export function lauchSplitInvoice(params) {
  return {
    type: key.LAUCH_SPLIT_INOIVCE,
    payload: newInvoice.POST('/split', params)
  }
}
export function checkSplitStatus(id, payType) {
  return {
    type: key.CHECK_SPLIT_STATUS,
    payload: newInvoice.GET('/split/$id', { id, payType })
  }
}
export function checkSplitStatusNew(id, payType) {
  return {
    type: key.CHECK_SPLIT_STATUS,
    payload: newInvoice.GET('/split/new/$id', { id, payType })
  }
}
export function getAdvInvoiceFields(params) {
  return invoiceActionV3.GET('/list/fields/$entityId', params)
}

export function getAdvInvoiceList(params) {
  return advInvoiceAction.POST('/search', { ...params })
}
//认证抵扣2.0, 获取发票列表
export function getImportInvoiceCheckList(params) {
  return certification.POST('/search/v2', { ...params })
}
//认证抵扣2.0, 获取发票认证配置
export function getCertificationConfig(params) {
  return certification.GET('/getConfig', params)
}

//认证抵扣2.0, 获取发票账期
export function getInvoiceTaxPeriod(params) {
  return certification.GET('/get/taxPeriod', params)
}

//认证抵扣2.0, 勾选发票
export function checkInvoiceSelect(params) {
  return certification.POST('/invoice/check', params)
}

//认证抵扣2.0, 设置为未勾选或无需勾选
export function setInvoiceCheck(params) {
  return certification.POST('/invoice', params)
}

//认证抵扣2.0, 同步勾选发票
export function syncInvoicesResult(params, qs) {
  return certification.POST('/invoice/check/result', params, qs)
}

//认证抵扣2.0, 导出全部统计
export function getExportSummary(params) {
  return certification.POST('/invoice/getExportSummary', params)
}

export function getUserConfig(params) {
  return certification.GET('/getUserConfig', params).catch((err) => {
    showMessage.error(err?.errorMessage)
  })
}

export async function setUserConfig(params) {
  return certification.POST('/setUserConfig', params)
}

export function checkInvoiceStatistic(params) {
  return {
    type: key.CHECK_INVOICE_STATISTIC,
    payload: certification.POST('/invoice/statistic', params)
  }
}

export function getPayerNoSummary(params) {
  return certification.GET('/get/payerNoSummary', params)
}

export function payerNoSummaryDetail(params) {
  return certification.GET('/get/payerNoSummaryDetail', params)
}

export function payerNoStatistic(params) {
  return certification.GET('/get/payerNoStatistic', params)
}

export function getAdvExportWay(params) {
  return invoiceActionV2Excel.POST('/export/exportWay', { ...params })
}

export function exportInvoiceBySync(params) {
  return invoiceActionV2Excel.POST('/export/sync', params, undefined, undefined, {
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
}

export function getSupplierAccountList(params) {
  return supplierAction.POST('/account/list')
}

export function getSupplierAccountDetail(params) {
  return supplierAction.GET('/account/$id/detail', params)
}

export function saveSupplierSetting(params, isUpdate) {
  if (isUpdate) {
    return newInvoice.PUT('/$supplierId', params)
  } else {
    return newInvoice.POST('/$supplierId', params)
  }
}
export function getSupplierUnInvoieList(params) {
  const { supplierAccountId, query, limit } = params
  return newInvoice.POST('/undo/bill/$supplierAccountId', { ...query, limit }, { supplierAccountId }).catch((err) => {
    showMessage.error(err.msg || err.errorMessage)
  })
}

export function selectAllSupplierUnInvoiceList(params) {
  const { supplierAccountId, query, limit } = params
  return newInvoice
    .POST('/undo/bill/selectall/$supplierAccountId', { ...query, limit }, { supplierAccountId })
    .catch((err) => {
      showMessage.error(err.msg || err.errorMessage)
    })
}

export function createOpenBillBatch(params) {
  return invoiceAction.POST('/unify/offline/new', params)
}

export function addInvoiceBagCode(params) {
  return newInvoice.PUT('/batch/$batchId/invoiceBagCode/$code', params)
}

export function openBillSpilt(params) {
  return newInvoice.POST('/split/new', params)
}

export function openBillSplitQuery(params) {
  return newInvoice.GET('/split/new/$supplierAccountId', params)
}

export function getBatchFeeDetailFields(params) {
  return newInvoice.GET('/fields/$batchId', params)
}

export function getBatchFeeDetails(params) {
  return newInvoice.POST('/getWaitImportBatchDetail/$batchId', params)
}

export function getBatchInvoices(body, params) {
  return reviewInvoice.POST('/bind/invoices', body, params)
}

export function feeDetailBindInvoice(params) {
  return reviewInvoice.POST('/bind/bindFlow', params)
}

export function invoiceBindFeeDetail(params) {
  return reviewInvoice.POST('/bind/bindInvoice', params)
}

export function getBatchInvoiceIds(params) {
  return invoiceAction.GET('/unify/getBatchInvoiceIds/$batchId', params).catch((err) => {
    showMessage.error(err?.errorMessage)
  })
}

export function getBindInvoiceCount(params) {
  return reviewInvoice.GET('/bind/invoices/count', params)
}

export function getUnImportDetailsNum(params) {
  return newInvoice.GET('/getUnImportDetailsNum/$batchId', params)
}

export function unBindInvoice(params) {
  return reviewInvoice.POST('/bind/unBindInvoice', params)
}

export function getUnifyFieldByBatchId(params) {
  return newInvoice.GET('/filed/$batchId', params)
}

export function getLog(params) {
  const join = {
    join: 'modifier,modifier,/v1/organization/staffs'
  }
  return {
    type: key.GET_LOG_LIST,
    payload: invoiceDataChange.POST('/findByTimeAndId', params, join)
  }
}

export function getMatchAgain(params) {
  return reviewInvoice.POST('/bind/match/again', params)
}

export function getDeleteAllInvoiceResult(params) {
  return reviewInvoice.POST('/bind/delete', params)
}

export function getUnbuildingInvoice(params) {
  return reviewInvoice.POST('/bind/unBindFlow', params)
}

export function getFeeDetail(params) {
  return reviewInvoice.GET('/bind/get/feeDetail', params)
}
