/**
 *  Created by panwei on 2018/7/11 下午4:16.
 */
export const ID = '@invoice-manage'

export default {
  ID: ID,
  CORPORATION_LIST: `${ID}/CORPORATION_LIST`,
  ADD_CORPORATION: `${ID}/ADD_CORPORATION`,
  INVOICE_UNDO_LIST: `${ID}/INVOICE_UNDO_LIST`,
  UNIFY_INVOICE_LIST: `${ID}/UNIFY_INVOICE_LIST`,
  OFFINE_INVOICE: `${ID}/OFFINE_INVOICE`,
  ONLINE_INVOICE: `${ID}/ONLINE_INVOICE`,
  ADD_INVOICE_TO_BATCH: `${ID}/ADD_INVOICE_TO_BATCH`,
  DELETE_BATCH: `${ID}/DELETE_BATCH`,
  UNTIFY_SELECT_ALL_BATCH: `${ID}/UNTIFY_SELECT_ALL_BATCH`,
  GET_INVOICE_LIST: `${ID}/GET_INVOICE_LIST`,
  GET_INVOICE_ITEM_LIST: `${ID}/GET_INVOICE_ITEM_LIST`,
  GET_INVOICE_RELATION: `${ID}/GET_INVOICE_RELATION`,
  GET_INVOICE_BATCH_DETAILS: `${ID}/GET_INVOICE_BATCH_DETAILS`,
  GET_BATCH_INFO_BY_ID: `${ID}/GET_BATCH_INFO_BY_ID`,
  GET_WAIT_ITEM_LIST: `${ID}/GET_WAIT_ITEM_LIST`,
  GET_INVOICE_LIST_TOTAL: `${ID}/GET_INVOICE_LIST_TOTAL`,
  GET_INVOICE_ITEM_TOTAL: `${ID}/GET_INVOICE_ITEM_TOTAL`,
  CALC_BACTH_FEEDETAIL_MONEY: `${ID}/CALC_BACTH_FEEDETAIL_MONEY`,
  GET_INVOICE_QUALIFICATION: `${ID}/GET_INVOICE_QUALIFICATION`,
  GET_RETRY_BATCH: `${ID}/GET_RETRY_BATCH`,
  REFRESH_INVOICING_LIST: `${ID}/REFRESH_INVOICING_LIST`,
  SET_CONFIRM_INVOICE: `${ID}/SET_CONFIRM_INVOICE`,
  WAIT_INVOICE_CONFIRMCHECK: `${ID}/WAIT_INVOICE_CONFIRMCHECK`,
  REMIND_SUP_INVOICE: `${ID}/REMIND_SUP_INVOICE`,
  REMIND_SUP_INVOICE_ALL: `${ID}/REMIND_SUP_INVOICE_ALL`,
  CONFIRM_INVOICE_BATCH: `${ID}/CONFIRM_INVOICE_BATCH`,
  GET_INVOICE_BY_IDS: `${ID}/GET_INVOICE_BY_IDS`,
  GET_INVOICE_BY_ENTITYID: `${ID}/GET_INVOICE_BY_ENTITYID`,
  GET_DISABLED_INVOICE_BY_ENTITYID: `${ID}/GET_DISABLED_INVOICE_BY_ENTITYID`,
  POST_CANCEL_DISABLED_INVOICE:`${ID}/POST_CANCEL_DISABLED_INVOICE`,
  POST_CANCEL_MARK_INVOICE:`${ID}/POST_CANCEL_MARK_INVOICE`,
  SAVE_COLLECTION_BILLS: `${ID}/SAVE_COLLECTION_BILLS`,
  GET_REVIEW_INVOICE_BY_FLOWIDS: `${ID}/GET_REVIEW_INVOICE_BY_FLOWIDS`,
  FN_MANUA_REVIEW_FLOW: `${ID}/FN_MANUA_REVIEW_FLOW`,
  FN_MANUA_REVIEW_INVOICE: `${ID}/FN_MANUA_REVIEW_INVOICE`,
  GET_REVIEW_CONFIG: `${ID}/GET_REVIEW_CONFIG`,
  SET_REVIEW_CONFIG: `${ID}/SET_REVIEW_CONFIG`,
  SEND_INVOICE_REVIEW_MESSAGE: `${ID}/SEND_INVOICE_REVIEW_MESSAGE`,
  GET_FLOWIDS_BY_TRACKNUN: `${ID}/GET_FLOWIDS_BY_TRACKNUN`,
  GET_TO_BE_CONFIRMED_INVOICE_LIST: `${ID}/GET_TO_BE_CONFIRMED_INVOICE_LIST`,
  TICK_INVOICE_BY_FILE_PATH: `${ID}/TICK_INVOICE_BY_FILE_PATH`,
  TICK_INVOICE_BY_FILE_KEY: `${ID}/TICK_INVOICE_BY_FILE_KEY`,
  CREATE_BATCH_BY_IDS: `${ID}/CREATE_BATCH_BY_IDS`,
  GET_BATCH_LIST: `${ID}/GET_BATCH_LIST`,
  GET_TICK_BATCH_DETAILS: `${ID}/GET_TICK_BATCH_DETAILS`,
  REMOVE_INVOICE_BY_IDS: `${ID}/REMOVE_INVOICE_BY_IDS`,
  CHECK_DEVICE_STATUS: `${ID}/CHECK_DEVICE_STATUS`,
  SELECT_ALL_INVOICE: `${ID}/SELECT_ALL_INVOICE`,
  CERTIFICATION_CAN_CHECK: `${ID}/CERTIFICATION_CAN_CHECK`,
  GET_CERTIFICATION_URL: `${ID}/GET_CERTIFICATION_URL`,

  GET_INVOICE_TAX_NUMS: `${ID}/GET_INVOICE_TAX_NUMS`,
  GET_PRICE_AND_TAX_SEPARATED_LIST: `${ID}/GET_PRICE_AND_TAX_SEPARATED_LIST`,
  DELETE_PRICE_AND_TAX_SEPARATED_LIST: `${ID}/DELETE_PRICE_AND_TAX_SEPARATED_LIST`,
  SAVE_PRICE_AND_TAX_SEPARATED_LIST: `${ID}/SAVE_PRICE_AND_TAX_SEPARATED_LIST`,
  MODIFY_PRICE_AND_TAX_SEPARATED_LIST: `${ID}/MODIFY_PRICE_AND_TAX_SEPARATED_LIST`,
  GET_WAIT_REVIEW_FLOW: `${ID}/GET_WAIT_REVIEW_FLOW`,

  GET_HOSE_TABLE_COLUMNS: `${ID}/GET_HOSE_TABLE_COLUMNS`,
  GET_HOSE_TABLE_INVOICING_COLUMNS: `${ID}/GET_HOSE_TABLE_INVOICING_COLUMNS`,
  CHECK_SPLIT_STATUS: `${ID}/CHECK_SPLIT_STATUS`,
  BATCH_BILLING: `${ID}/BATCH_BILLING`,
  LAUCH_SPLIT_INOIVCE: `${ID}/LAUCH_SPLIT_INOIVCE`,
  GET_ADV_INVOICE_LIST_TOTAL: `${ID}/GET_ADV_INVOICE_LIST_TOTAL`,
  GET_LOG_LIST: `${ID}/GET_LOG_LIST`,
  GET_RULE_LIST_FOR_REASON: `${ID}/GET_RULE_LIST_FOR_REASON`, // 通过发票规范列表获取发票连号配置
  CHECK_INVOICE_STATISTIC: `${ID}/CHECK_INVOICE_STATISTIC`
}
